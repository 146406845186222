.leaflet-pm-toolbar .leaflet-pm-icon-save {
    background-image: url("../../../../public/static/illustrations/floppy-disk.svg");
}

.leaflet-pm-toolbar .leaflet-pm-icon-soft-reset {
    background-image: url("../../../../public/static/illustrations/back-arrow.svg");
}

.leaflet-pm-toolbar .leaflet-pm-icon-lock {
    background-image: url("../../../../public/static/illustrations/lock.svg");
}

.leaflet-pm-toolbar .leaflet-pm-icon-unlock {
    background-image: url("../../../../public/static/illustrations/unlock.svg");
}

.leaflet-pm-toolbar .leaflet-pm-icon-trash {
    background-image: url("../../../../public/static/illustrations/trash.svg");
}

.leaflet-tooltip-custom {
    background: none;
    border: none;
    box-shadow: none;
}
